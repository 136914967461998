import styled from 'styled-components';

import { media } from 'components/_settings/variables';
import { Title } from 'components/shared/title';

import Stripes from './images/employee-benefits-difference-stripes.svg';

export const StyledStripes = styled(Stripes)`
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 100%;
  min-width: 1200px;
  transform: translateX(-50%);

  @media only screen and (min-width: 1920.98px) {
    display: none;
  }

  ${media.xs} {
    bottom: -50px;
  }
`;

export const StyledTitle = styled(Title)`
  max-width: 550px;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(285deg, #ffe3e1, #956fa8);
  margin-bottom: 45px;

  ${media.sm} {
    margin-bottom: 30px;
  }
`;

export const Wrapper = styled.section`
  position: relative;
  background-image: linear-gradient(333deg, #08082d, #151548);
  padding-top: 150px;
  padding-bottom: 330px;

  @media only screen and (min-width: 1920.98px) {
    padding-bottom: 170px;
  }

  ${media.sm} {
    padding-top: 50px;
    padding-bottom: 160px;
  }

  ${media.xs} {
    padding-bottom: 100px;
  }
`;
