import styled from 'styled-components';

import { color, media } from 'components/_settings/variables';
import { rgba } from 'utils';

export const WayWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  ${media.sm} {
    max-width: 400px;
    min-height: 200px;
    margin-right: auto;
    margin-left: auto;
  }

  svg {
    width: 100%;
  }
`;

export const SideName = styled.h3`
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${color.text.tertiary};
  margin-top: 0;
  margin-bottom: 0;

  ${media.sm} {
    margin-bottom: 20px;
  }
`;

export const Side = styled.div`
  flex-basis: 50%;

  ${media.sm} {
    margin-bottom: 60px;
  }

  &:first-child {
    border-right: 1px solid ${rgba('#979797', 0.2)};
    padding-right: 40px;

    ${media.md} {
      padding-right: 20px;
    }

    ${media.sm} {
      border-right: 0;
      padding-right: 0;
    }
  }

  &:last-child {
    padding-left: 40px;

    ${media.md} {
      padding-left: 20px;
    }

    ${media.sm} {
      padding-left: 0;
      margin-bottom: 0;
    }
  }
`;

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  border-top: 1px solid ${rgba('#979797', 0.2)};
  padding-top: 30px;

  ${media.sm} {
    display: block;
  }
`;
