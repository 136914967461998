import * as React from 'react';

import { Benefits } from './benefits';
import { Wrapper, Inner, Content, StyledTitle } from './employee-benefits-discount.styles';
import { Illustration } from './illustration';

export const Discount = ({ title, benefits }) => (
  <Wrapper>
    <Inner>
      <Content>
        <StyledTitle as="h2" size="xl">
          {title}
        </StyledTitle>
        <Benefits items={benefits} />
      </Content>

      <Illustration />
    </Inner>
  </Wrapper>
);
