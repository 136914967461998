import styled from 'styled-components';

import { fontSize, color, media } from 'components/_settings/variables';

export const Note = styled.p`
  position: relative;
  max-width: 430px;
  width: 100%;
  font-size: 26px;
  font-weight: 500;
  line-height: normal;
  color: ${color.text.secondary};
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: -24px 24px 119px -44px rgba(50, 50, 93, 0.5);
  padding-top: 25px;
  padding-right: 35px;
  padding-bottom: 25px;
  padding-left: 35px;
  margin-top: 0;
  margin-right: auto;
  margin-bottom: 0;
  margin-left: auto;

  ${media.sm} {
    max-width: 350px;
    font-size: ${fontSize.md};
  }

  ${media.xs} {
    padding-right: 20px;
    padding-left: 20px;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 0;
    height: 0;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #ffffff;
    border-left: 10px solid transparent;
    transform: translateY(-100%) translateX(-50%);
  }

  strong {
    font-weight: 500;
    color: #8972e9;
  }
`;

export const Image = styled.img`
  max-width: 100%;
  margin-bottom: 40px;

  ${media.md} {
    max-width: 450px;
  }

  ${media.sm} {
    max-width: 300px;
    width: 70%;
  }
`;

export const Wrapper = styled.div`
  text-align: center;

  ${media.lg} {
    max-width: 450px;
  }

  ${media.md} {
    max-width: 100%;
  }
`;
