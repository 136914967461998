import styled from 'styled-components';

import { media } from 'components/_settings/variables';
import { Container } from 'components/shared/container';

import BackgroundTriangle from './images/employee-benefits-speed-testimonials-background-triangle.svg';

export const StyledBackgroundTriangle = styled(BackgroundTriangle)`
  position: absolute;
  right: 0;
  bottom: -1px;
  width: 460px;

  @media only screen and (min-width: 1920.98px) {
    display: none;
  }

  ${media.lg} {
    width: 360px;
  }

  ${media.md} {
    width: 300px;
  }

  ${media.sm} {
    width: 260px;
  }

  ${media.xs} {
    width: 175px;
    right: -25px;
    bottom: -25px;
  }
`;

export const ItemsWrapper = styled.div`
  display: flex;
  margin-bottom: 75px;

  ${media.md} {
    display: block;
  }

  ${media.sm} {
    margin-bottom: 40px;
  }
`;

export const Inner = styled(Container)`
  border-top: 1px solid #d9dfe4;
  padding-top: 40px;

  ${media.md} {
    display: block;
    border-top: 0;
  }
`;

export const Wrapper = styled.section`
  position: relative;
  background-color: #fbfbfe;
  padding-bottom: 140px;
  overflow: hidden;

  ${media.sm} {
    padding-bottom: 80px;
  }
`;
