import * as React from 'react';

import { getTextWithoutParagraph } from 'utils';

import { Wrapper, Image, Note } from './employee-benefits-speed-illustration.styles';

export const IllustrationView = ({ text, imageSrc }) => (
  <Wrapper>
    <Image src={imageSrc} alt="" />
    <Note dangerouslySetInnerHTML={{ __html: getTextWithoutParagraph(text) }} />
  </Wrapper>
);
