import { motion } from 'framer-motion';
import { Link } from 'gatsby';
import * as React from 'react';
import { useInView } from 'react-intersection-observer';

import { RevealTextFromTop, FadeInBottom } from 'animations';
import { Button } from 'components/shared/button';
import { getTextWithoutParagraph } from 'utils';

import {
  Wrapper,
  Inner,
  Content,
  StyledTitle,
  Description,
  StyledIllustration,
  BackgroundIllustrationWrapper,
  StyledBackgroundIllustration,
  StyledBackgroundTriangle,
  IllustrationWrapper,
  StarbucksWrapper,
  UberWrapper,
  NikeWrapper,
  MainIllustrationWrapper,
} from './employee-benefits-intro.styles';
import NikeCard from './images/nike.svg';
import StarbucksCard from './images/starbucks.svg';
import UberCard from './images/uber.svg';

const containerAnimationVariants = {
  hidden: {},
  visible: {
    transition: {
      delay: 0.1,
      staggerChildren: 0.1,
    },
  },
};

const MainIlliustrationVariants = {
  hidden: { opacity: 0, y: 30 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.8,
    },
  },
};

const NikeAnimationVariants = {
  hidden: { opacity: 0, x: 30 },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.8,
    },
  },
};

const UberAnimationVariants = {
  hidden: { opacity: 0, x: -30 },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.8,
    },
  },
};
const StarbucksAnimationVariants = {
  hidden: { y: 40, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.8,
    },
  },
};

export const Intro = (props) => {
  const { title, description, buttonText } = props;

  const [mainIllustrationRef, inView] = useInView({
    threshold: 0.1,
  });

  return (
    <Wrapper>
      <Inner>
        <Content>
          <motion.div initial="hidden" animate="visible" variants={containerAnimationVariants}>
            <StyledTitle size="xxl">
              {title.split('<br/>').map((part, index) => (
                <motion.div
                  key={`intro-title-${index}`}
                  variants={RevealTextFromTop}
                  dangerouslySetInnerHTML={{
                    __html: getTextWithoutParagraph(part),
                  }}
                />
              ))}
            </StyledTitle>
            <motion.div variants={FadeInBottom}>
              <Description>{description}</Description>
            </motion.div>
            <motion.div variants={FadeInBottom}>
              <Button component={Link} theme="light" to="/demo/">
                {buttonText}
              </Button>
            </motion.div>
          </motion.div>
        </Content>

        <IllustrationWrapper>
          <motion.div
            initial="hidden"
            ref={mainIllustrationRef}
            animate={inView ? 'visible' : 'hidden'}
            variants={containerAnimationVariants}
          >
            <MainIllustrationWrapper variants={MainIlliustrationVariants}>
              <StyledIllustration />
            </MainIllustrationWrapper>
            <StarbucksWrapper variants={StarbucksAnimationVariants}>
              <StarbucksCard />
            </StarbucksWrapper>
            <NikeWrapper variants={NikeAnimationVariants}>
              <NikeCard />
            </NikeWrapper>
            <UberWrapper variants={UberAnimationVariants}>
              <UberCard />
            </UberWrapper>
          </motion.div>
        </IllustrationWrapper>
      </Inner>

      <BackgroundIllustrationWrapper>
        <StyledBackgroundIllustration />
      </BackgroundIllustrationWrapper>

      <StyledBackgroundTriangle />
    </Wrapper>
  );
};
