import * as React from 'react';

import { Wrapper, Side, SideName, WayWrapper } from './employee-benefits-difference-ways.styles';
import OldWay from './images/old-way.svg';
import WegiftWay from './images/wegift-way.svg';

export const Ways = ({ oldWayTitle, newWayTitle }) => (
  <Wrapper>
    <Side>
      <SideName style={{ color: '#945f7c' }}>{oldWayTitle}</SideName>
      <WayWrapper>
        <OldWay />
      </WayWrapper>
    </Side>
    <Side>
      <SideName>{newWayTitle}</SideName>
      <WayWrapper>
        <WegiftWay />
      </WayWrapper>
    </Side>
  </Wrapper>
);
