import styled from 'styled-components';

import { media } from 'components/_settings/variables';
import { Container } from 'components/shared/container';
import { Title } from 'components/shared/title';

export const Description = styled.p`
  max-width: 410px;
  line-height: 1.6;
  margin-top: 0;
  margin-bottom: 40px;

  ${media.md} {
    max-width: 100%;
  }

  ${media.sm} {
    margin-bottom: 30px;
  }
`;

export const StyledTitle = styled(Title)`
  margin-bottom: 15px;

  ${media.sm} {
    margin-bottom: 10px;
  }
`;

export const Content = styled.div`
  order: 1;
  max-width: 540px;

  ${media.lg} {
    max-width: 450px;
  }

  ${media.md} {
    max-width: 100%;
    margin-bottom: 100px;
  }

  ${media.sm} {
    margin-bottom: 75px;
  }
`;

export const Inner = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${media.md} {
    display: block;
  }
`;

export const Wrapper = styled.section`
  background-color: #fbfbfe;
  padding-bottom: 220px;

  ${media.sm} {
    padding-bottom: 150px;
  }
`;
