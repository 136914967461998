import styled from 'styled-components';

import { media } from 'components/_settings/variables';
import { Title } from 'components/shared/title';

export const Description = styled.p`
  line-height: 1.6;
  margin-top: 0;
  margin-bottom: 0;
`;

export const ItemTitle = styled(Title)`
  color: #6b63d1;
  margin-bottom: 5px;
`;

export const Item = styled.li`
  max-width: 290px;
  margin-right: 60px;

  ${media.lg} {
    margin-right: 20px;
  }

  ${media.md} {
    max-width: calc(50% - 60px);
    margin-right: 30px;
    margin-left: 30px;
  }

  ${media.sm} {
    max-width: calc(50% - 30px);
    margin-right: 15px;
    margin-left: 15px;
  }

  ${media.xs} {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 60px;
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;

    ${media.md} {
      margin-right: 30px;
    }

    ${media.sm} {
      margin-right: 15px;
    }

    ${media.xs} {
      margin-right: 0;
      margin-bottom: 0;
    }
  }
`;

export const Wrapper = styled.ul`
  display: flex;
  align-items: flex-start;
  list-style-type: none;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;

  ${media.md} {
    margin-right: -30px;
    margin-left: -30px;
  }

  ${media.sm} {
    margin-right: -15px;
    margin-left: -15px;
  }

  ${media.xs} {
    display: block;
    margin-right: 0;
    margin-left: 0;
  }
`;
