import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';

import { IllustrationView } from './employee-benefits-speed-illustration.view';

export const Illustration = ({ text }) => {
  const { illustration } = useStaticQuery(graphql`
    query {
      illustration: file(
        relativePath: {
          eq: "pages/employee-benefits/speed/employee-benefits-speed-illustration.svg"
        }
      ) {
        publicURL
      }
    }
  `);

  return <IllustrationView text={text} imageSrc={illustration.publicURL} />;
};
