import * as React from 'react';

import { Container } from 'components/shared/container';

import { Benefits } from './benefits';
import { Wrapper, StyledTitle, StyledStripes } from './employee-benefits-difference.styles';
import { Ways } from './ways';

export const Difference = ({ title, benefits, oldWayTitle, newWayTitle }) => (
  <Wrapper>
    <Container>
      <StyledTitle as="h2" size="xxl">
        {title}
      </StyledTitle>
      <Benefits items={benefits} />
      <Ways oldWayTitle={oldWayTitle} newWayTitle={newWayTitle} />
    </Container>
    <StyledStripes />
  </Wrapper>
);
