import styled from 'styled-components';

import { fontSize, color, media } from 'components/_settings/variables';

export const AuthorRole = styled.span`
  font-size: ${fontSize.sm};
  line-height: 1.75;
  color: #6b7696;
`;

export const AuthorName = styled.h3`
  font-size: ${fontSize.sm};
  line-height: 1.75;
  font-weight: 700;
  color: ${color.text.secondary};
  margin-top: 0;
  margin-bottom: 0;
`;

export const Text = styled.p`
  line-height: 1.6;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(108deg, #0080a9, #42d8ef);
  margin-top: 0;
  margin-bottom: 30px;

  ${media.sm} {
    margin-bottom: 15px;
  }
`;

export const Content = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 480px;

  ${media.md} {
    max-width: 100%;
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 80px;
  margin-bottom: 20px;

  ${media.sm} {
    display: block;
    min-height: initial;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 50%;

  ${media.md} {
    margin-bottom: 50px;
  }

  ${media.sm} {
    margin-bottom: 35px;
  }

  &:first-child {
    border-right: 1px solid #d9dfe4;
    padding-right: 50px;

    ${media.md} {
      border-right: 0;
      padding-right: 0;
    }
  }

  &:last-child {
    padding-left: 50px;

    ${media.md} {
      padding-left: 0;
      margin-bottom: 0;
    }
  }
`;
