import * as React from 'react';

import {
  Wrapper,
  Item,
  StyledTitle,
  Description,
} from './employee-benefits-difference-benefits.styles';

export const Benefits = ({ items }) => (
  <Wrapper>
    {items.map(({ title, description }) => (
      <Item key={title}>
        <StyledTitle as="h3" size="xs">
          {title}
        </StyledTitle>
        <Description>{description}</Description>
      </Item>
    ))}
  </Wrapper>
);
