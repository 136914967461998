import * as React from 'react';

import { Benefits } from './benefits';
import {
  Wrapper,
  Inner,
  Content,
  StyledTitle,
  Description,
} from './employee-benefits-speed.styles';
import { Illustration } from './illustration';

export const Speed = (props) => {
  const {
    title,
    description,
    benefit1Title,
    benefit1Description,
    benefit2Title,
    benefit2Description,
    benefit3Title,
    benefit3Description,
    illustrationText,
  } = props;

  return (
    <Wrapper>
      <Inner>
        <Content>
          <StyledTitle as="h2" size="xl">
            {title}
          </StyledTitle>
          <Description>{description}</Description>
          <Benefits
            item1Title={benefit1Title}
            item1Description={benefit1Description}
            item2Title={benefit2Title}
            item2Description={benefit2Description}
            item3Title={benefit3Title}
            item3Description={benefit3Description}
          />
        </Content>
        <Illustration text={illustrationText} />
      </Inner>
    </Wrapper>
  );
};
