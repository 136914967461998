import { Link } from 'gatsby';
import * as React from 'react';

import { Button } from 'components/shared/button';

import {
  Wrapper,
  Inner,
  ItemsWrapper,
  StyledBackgroundTriangle,
} from './employee-benefits-testimonials.styles';
import { Item } from './item';

export const Testimonials = ({ items, buttonText }) => (
  <Wrapper>
    <Inner>
      <ItemsWrapper>
        {items.map(({ text, author, logo }) => (
          <Item text={text} author={author} logo={logo} key={text} />
        ))}
      </ItemsWrapper>

      <Button theme="light" component={Link} to="/demo/">
        {buttonText}
      </Button>
    </Inner>

    <StyledBackgroundTriangle />
  </Wrapper>
);
