import styled from 'styled-components';

import { media } from 'components/_settings/variables';
import { Title } from 'components/shared/title';

export const Description = styled.p`
  max-width: 420px;
  line-height: 1.6;
  margin-top: 0;
  margin-bottom: 0;

  ${media.md} {
    max-width: 100%;
  }
`;

export const ItemTitle = styled(Title)`
  color: #806de2;
  margin-bottom: 5px;
`;

export const ItemIconWrapper = styled.div`
  margin-right: 20px;
`;

export const Item = styled.li`
  display: flex;
  align-items: flex-start;
  margin-bottom: 40px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Wrapper = styled.ul`
  list-style-type: none;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
`;
