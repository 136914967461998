import styled from 'styled-components';

import { media } from 'components/_settings/variables';

export const Wrapper = styled.img`
  max-width: 100%;

  ${media.md} {
    display: block;
    max-width: 350px;
    width: 100%;
    padding-right: 40px;
    margin-right: auto;
    margin-left: auto;
  }
`;
