import * as React from 'react';

import Icon1 from '../icons/employee-benefits-speed-icon1.svg';
import Icon2 from '../icons/employee-benefits-speed-icon2.svg';
import Icon3 from '../icons/employee-benefits-speed-icon3.svg';

import {
  Wrapper,
  Item,
  ItemIconWrapper,
  ItemTitle,
  Description,
} from './employee-benefits-speed-benefits.styles';

export const Benefits = (props) => {
  const {
    item1Title,
    item1Description,
    item2Title,
    item2Description,
    item3Title,
    item3Description,
  } = props;

  return (
    <Wrapper>
      <Item>
        <ItemIconWrapper>
          <Icon1 />
        </ItemIconWrapper>

        <div>
          <ItemTitle as="h3" size="xs">
            {item1Title}
          </ItemTitle>
          <Description>{item1Description}</Description>
        </div>
      </Item>

      <Item>
        <ItemIconWrapper>
          <Icon2 />
        </ItemIconWrapper>

        <div>
          <ItemTitle as="h3" size="xs">
            {item2Title}
          </ItemTitle>
          <Description>{item2Description}</Description>
        </div>
      </Item>

      <Item>
        <ItemIconWrapper>
          <Icon3 />
        </ItemIconWrapper>

        <div>
          <ItemTitle as="h3" size="xs">
            {item3Title}
          </ItemTitle>
          <Description>{item3Description}</Description>
        </div>
      </Item>
    </Wrapper>
  );
};
