import { motion } from 'framer-motion';
import styled from 'styled-components';

import { fontSize, color, media } from 'components/_settings/variables';
import { Container } from 'components/shared/container';
import { Title } from 'components/shared/title';

import LabelIcon from './icons/employee-benefits-intro-label-icon.svg';
import BackgroundIllustration from './images/employee-benefits-intro-background-illustration.svg';
import BackgroundTriangle from './images/employee-benefits-intro-background-triangle.svg';
import IllustrationMain from './images/main.svg';

export const StyledBackgroundTriangle = styled(BackgroundTriangle)`
  position: absolute;
  bottom: 0;
  left: 0;

  @media only screen and (min-width: 1920.98px) {
    display: none;
  }

  ${media.xl} {
    bottom: -75px;
    left: -75px;
  }

  ${media.xs} {
    display: none;
  }
`;

export const StyledBackgroundIllustration = styled(BackgroundIllustration)`
  position: absolute;
  top: -775px;
  right: -600px;
  height: 2280px;

  ${media.lg} {
    right: -700px;
  }

  ${media.md} {
    top: auto;
    right: -250px;
    bottom: -375px;
    height: 1100px;
  }

  ${media.sm} {
    bottom: -450px;
  }

  ${media.xs} {
    right: -190px;
    bottom: -400px;
    height: 875px;
  }
`;

export const BackgroundIllustrationWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  max-width: 1440px;
  width: 100%;
  height: 100%;
  transform: translateX(-50%);

  @media only screen and (min-width: 1920.98px) {
    display: none;
  }

  ${media.lg} {
    max-width: 992px;
  }

  ${media.md} {
    max-width: 768px;
  }

  ${media.sm} {
    max-width: 576px;
  }
`;

export const Description = styled.p`
  max-width: 540px;
  line-height: 1.5;
  color: ${color.text.secondary};
  margin-top: 0;
  margin-bottom: 40px;

  ${media.lg} {
    max-width: 450px;
  }

  ${media.sm} {
    margin-bottom: 25px;
  }
`;

export const StyledTitle = styled(Title)`
  max-width: 560px;
  margin-bottom: 35px;

  ${media.sm} {
    margin-bottom: 25px;
  }
`;

export const LabelText = styled.span`
  font-size: ${fontSize.sm};
  font-weight: 500;
  line-height: normal;
  color: ${color.primary};
`;

export const StyledLabelIcon = styled(LabelIcon)`
  flex-shrink: 0;
  margin-right: 20px;

  ${media.xs} {
    margin-right: 0;
    margin-bottom: 10px;
  }
`;

export const Label = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  ${media.xs} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Content = styled.div`
  ${media.sm} {
    margin-bottom: 30px;
  }
`;

export const Inner = styled(Container)`
  position: relative;
  z-index: 1;
`;

export const Wrapper = styled.section`
  position: relative;
  background-image: linear-gradient(200deg, #d9fbff, #ffffff);
  padding-top: 185px;
  padding-bottom: 210px;
  overflow: hidden;

  ${media.sm} {
    padding-top: 90px;
    padding-bottom: 0;
  }
`;
export const IllustrationWrapper = styled.div`
  position: absolute;
  top: 4%;
  right: 3%;

  ${media.lg} {
    top: 20px;
    width: 400px;
    right: 0;
  }

  ${media.md} {
    top: auto;
    bottom: -200px;
    width: 400px;
  }

  ${media.sm} {
    position: relative;
    bottom: -30px;
    max-width: 400px;
    display: block;
    width: 65%;
    margin: 0 auto;
    padding-bottom: 50px;
  }
`;

export const MainIllustrationWrapper = styled(motion.div)`
  box-shadow: 0 19px 32px -12px rgba(44, 41, 93, 0.5);
  border-radius: 0 0 12px 12px;
`;
export const StyledIllustration = styled(IllustrationMain)`
  height: auto;
  z-index: 1;
  display: block;
  ${media.lg} {
    width: 100%;
  }
`;

export const UberWrapper = styled(motion.div)`
  position: absolute;
  top: 23.4%;
  left: 77%;
  width: 28.5%;
  z-index: 2;
  box-shadow: 0 19px 32px -12px rgba(44, 41, 93, 0.5);
  border-radius: 8px;
  svg {
    width: 100%;
    height: auto;
    display: block;
  }
`;

export const NikeWrapper = styled(motion.div)`
  position: absolute;
  top: 45%;
  left: -11.1%;
  width: 28.5%;
  z-index: 2;
  box-shadow: 0 19px 32px -12px rgba(44, 41, 93, 0.5);
  border-radius: 8px;
  svg {
    width: 100%;
    height: auto;
    display: block;
  }
`;

export const StarbucksWrapper = styled(motion.div)`
  position: absolute;
  top: -14%;
  left: 17.6%;
  width: 28.5%;
  z-index: 2;
  box-shadow: 0 19px 32px -12px rgba(44, 41, 93, 0.5);
  border-radius: 8px;
  svg {
    width: 100%;
    height: auto;
    display: block;
  }
`;
