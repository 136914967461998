import { graphql } from 'gatsby';
import * as React from 'react';

import Layout from 'components/_settings/layout';
import { Automate } from 'components/blocks/automate';
import { Brands } from 'components/blocks/brands';
import { Footer } from 'components/blocks/footer';
import { GetStarted } from 'components/blocks/get-started';
import { Header } from 'components/blocks/header';
import { SolutionsCTA } from 'components/blocks/solutions-cta';
import { Difference } from 'components/pages/employee-benefits/difference';
import { Discount } from 'components/pages/employee-benefits/discount';
import { Intro } from 'components/pages/employee-benefits/intro';
import { Speed } from 'components/pages/employee-benefits/speed';
import { Testimonials } from 'components/pages/employee-benefits/testimonials';

import { buildPageMetadata } from '../utils/data-builders';

const EmployeeBenefits = (props) => {
  const {
    data: { wpPage },
  } = props;

  const {
    section1TextAboveTitle,
    section1Title,
    section1Description,
    section1ButtonText,

    brandsTitle,
    brandsDescription,

    section3Title,
    section3Description,
    section3Feature1Title,
    section3Feature1Description,
    section3Feature2Title,
    section3Feature2Description,
    section3Feature3Title,
    section3Feature3Description,
    section3IllustrationText,

    section4Title,
    section4Feature1Title,
    section4Feature1Description,
    section4Feature2Title,
    section4Feature2Description,

    section5Testimonial1Logo,
    section5Testimonial1Text,
    section5Testimonial1Author,
    section5Testimonial1AuthorRole,
    section5Testimonial2Logo,
    section5Testimonial2Text,
    section5Testimonial2Author,
    section5Testimonial2AuthorRole,
    section5ButtonText,

    section8Title,
    section8Feature1Title,
    section8Feature1Description,
    section8Feature2Title,
    section8Feature2Description,
    section8Feature3Title,
    section8Feature3Description,
    section8OldWayTitle,
    section8NewWayTitle,

    solutionsCtaTitle,
    solutionsCtaButtonText,
    solutionsCtaButtonUrl,
    solutionsCtaLogos,
    solutionsCtaTestimonialAuthorPhoto,
    solutionsCtaTestimonialText,
    solutionsCtaTestimonialAuthorRole,
    solutionsCtaTestimonialAuthorCompany,
    solutionsCtaTestimonialAuthorName,

    showHeader,
    showFooter,
  } = wpPage.acf;

  const discountBenefits = [
    {
      title: section4Feature1Title,
      description: section4Feature1Description,
    },
    {
      title: section4Feature2Title,
      description: section4Feature2Description,
    },
  ];

  const testimonialsItems = [
    {
      logo: section5Testimonial1Logo,
      text: section5Testimonial1Text,
      author: {
        name: section5Testimonial1Author,
        role: section5Testimonial1AuthorRole,
      },
    },
    {
      logo: section5Testimonial2Logo,
      text: section5Testimonial2Text,
      author: {
        name: section5Testimonial2Author,
        role: section5Testimonial2AuthorRole,
      },
    },
  ];

  const solutionsCTATestimonial = {
    text: solutionsCtaTestimonialText,
    author: {
      photo: solutionsCtaTestimonialAuthorPhoto,
      role: solutionsCtaTestimonialAuthorRole,
      company: solutionsCtaTestimonialAuthorCompany,
      name: solutionsCtaTestimonialAuthorName,
    },
  };

  const differenceBenefits = [
    {
      title: section8Feature1Title,
      description: section8Feature1Description,
    },
    {
      title: section8Feature2Title,
      description: section8Feature2Description,
    },
    {
      title: section8Feature3Title,
      description: section8Feature3Description,
    },
  ];

  return (
    <Layout pageMetadata={buildPageMetadata(wpPage)}>
      <Header withoutActions={showHeader === 'false'} />
      <Intro
        labelText={section1TextAboveTitle}
        title={section1Title}
        description={section1Description}
        buttonText={section1ButtonText}
      />
      <Brands title={brandsTitle} description={brandsDescription} />
      <Speed
        title={section3Title}
        description={section3Description}
        benefit1Title={section3Feature1Title}
        benefit1Description={section3Feature1Description}
        benefit2Title={section3Feature2Title}
        benefit2Description={section3Feature2Description}
        benefit3Title={section3Feature3Title}
        benefit3Description={section3Feature3Description}
        illustrationText={section3IllustrationText}
      />
      <Discount title={section4Title} benefits={discountBenefits} />
      <Testimonials items={testimonialsItems} buttonText={section5ButtonText} />
      <Automate />
      <SolutionsCTA
        title={solutionsCtaTitle}
        buttonText={solutionsCtaButtonText}
        buttonUrl={solutionsCtaButtonUrl}
        logos={solutionsCtaLogos}
        testimonial={solutionsCTATestimonial}
      />
      <Difference
        title={section8Title}
        benefits={differenceBenefits}
        oldWayTitle={section8OldWayTitle}
        newWayTitle={section8NewWayTitle}
      />
      <GetStarted />
      {showFooter !== 'false' && <Footer />}
    </Layout>
  );
};

export default EmployeeBenefits;

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      ...wordpressPagePageMetadata
      acf {
        section1Title
        section1Description
        section1ButtonText

        brandsTitle
        brandsDescription

        section3Title
        section3Description
        section3Feature1Title
        section3Feature1Description
        section3Feature2Title
        section3Feature2Description
        section3Feature3Title
        section3Feature3Description
        section3IllustrationText

        section4Title
        section4Feature1Title
        section4Feature1Description
        section4Feature2Title
        section4Feature2Description

        section5Testimonial1Logo {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 150) {
                presentationWidth
                presentationHeight
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        section5Testimonial1Text
        section5Testimonial1Author
        section5Testimonial1AuthorRole
        section5Testimonial2Logo {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 150) {
                presentationWidth
                presentationHeight
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        section5Testimonial2Text
        section5Testimonial2Author
        section5Testimonial2AuthorRole
        section5ButtonText

        section8Title
        section8Feature1Title
        section8Feature1Description
        section8Feature2Title
        section8Feature2Description
        section8Feature3Title
        section8Feature3Description
        section8OldWayTitle
        section8NewWayTitle

        solutionsCtaTitle
        solutionsCtaButtonText
        solutionsCtaButtonUrl {
          ... on WpPage {
            uri
          }
        }
        solutionsCtaLogos {
          id
          altText
          localFile {
            childImageSharp {
              fluid {
                presentationWidth
                presentationHeight
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        solutionsCtaTestimonialAuthorPhoto {
          altText
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        solutionsCtaTestimonialText
        solutionsCtaTestimonialAuthorRole
        solutionsCtaTestimonialAuthorCompany
        solutionsCtaTestimonialAuthorName
        showHeader
        showFooter
      }
    }
  }
`;
