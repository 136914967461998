import styled from 'styled-components';

import { fontSize, color, media } from 'components/_settings/variables';
import { Title } from 'components/shared/title';

export const Description = styled.p`
  font-size: ${fontSize.sm};
  line-height: 1.75;
  color: ${color.text.secondary};
  margin-top: 0;
  margin-bottom: 0;
`;

export const StyledTitle = styled(Title)`
  font-weight: 500;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(97deg, #ffe3e1, #956fa8);
  margin-bottom: 5px;
`;

export const Item = styled.li`
  max-width: 320px;
  margin-right: 30px;

  ${media.sm} {
    max-width: 100%;
    margin-bottom: 30px;
  }

  &:last-child {
    ${media.sm} {
      margin-bottom: 0;
    }
  }
`;

export const Wrapper = styled.ul`
  display: flex;
  list-style-type: none;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 50px;

  ${media.sm} {
    display: block;
    margin-bottom: 30px;
  }
`;
