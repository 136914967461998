import GatsbyImage from 'gatsby-image';
import * as React from 'react';

import { getFluidImage } from 'utils';

import {
  Wrapper,
  LogoWrapper,
  Content,
  Text,
  AuthorName,
  AuthorRole,
} from './employee-benefits-testimonials-item.styles';

export const Item = ({ logo, text, author }) => {
  const fluid = getFluidImage(logo.localFile);

  return (
    <Wrapper>
      <LogoWrapper>
        <GatsbyImage
          style={{
            width: fluid.presentationWidth,
            maxHeight: 80,
          }}
          imgStyle={{
            objectFit: 'contain',
            objectPosition: 'left center',
          }}
          fluid={fluid}
          alt={logo.altText}
          loading="eager"
        />
      </LogoWrapper>

      <Content>
        <Text>{text}</Text>

        <div>
          <AuthorName>{author.name}</AuthorName>
          <AuthorRole>{author.role}</AuthorRole>
        </div>
      </Content>
    </Wrapper>
  );
};
