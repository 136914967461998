import styled from 'styled-components';

import { media } from 'components/_settings/variables';
import { Container } from 'components/shared/container';
import { Title } from 'components/shared/title';

export const StyledTitle = styled(Title)`
  max-width: 450px;
  margin-bottom: 60px;

  ${media.sm} {
    max-width: 100%;
    margin-bottom: 30px;
  }
`;

export const Content = styled.div`
  ${media.md} {
    margin-bottom: 50px;
  }
`;

export const Inner = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  ${media.md} {
    display: block;
  }
`;

export const Wrapper = styled.section`
  background-color: #fbfbfe;
  padding-bottom: 160px;

  ${media.sm} {
    padding-bottom: 85px;
  }
`;
